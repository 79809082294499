// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UploadRangeModal_modal-box__jVlEs {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 400px;\n  height: 400px;\n  background-color: white;\n  border: 2px solid #000;\n}\n.UploadRangeModal_modal-box__jVlEs .UploadRangeModal_content__24cHE {\n  margin-right: 15px;\n  font-size: 18px;\n  line-height: 20px;\n  color: #224e77;\n}\n.UploadRangeModal_modal-box__jVlEs .UploadRangeModal_text__gmajT {\n  margin-top: 5px;\n  text-align: center;\n}\n.UploadRangeModal_modal-box__jVlEs .UploadRangeModal_btn__8jltw {\n  margin-top: 5px;\n}\n.UploadRangeModal_modal-box__jVlEs .UploadRangeModal_modal-component__lWcXv {\n  margin-top: 30px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-box": "UploadRangeModal_modal-box__jVlEs",
	"content": "UploadRangeModal_content__24cHE",
	"text": "UploadRangeModal_text__gmajT",
	"btn": "UploadRangeModal_btn__8jltw",
	"modal-component": "UploadRangeModal_modal-component__lWcXv"
};
export default ___CSS_LOADER_EXPORT___;
