// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TopBanner_top-banner__82UmL {\n  background-color: #545050;\n  height: 70px;\n  position: relative;\n}\n.TopBanner_top-banner__82UmL .TopBanner_text__0J5As {\n  margin-left: 2%;\n  color: #FFFFFF;\n  font-size: 20px;\n  line-height: 20px;\n  white-space: nowrap;\n}\n.TopBanner_top-banner__82UmL .TopBanner_user-btn__EqunN {\n  margin-right: 2%;\n  font-size: 20px;\n  line-height: 14px;\n  white-space: nowrap;\n  width: 136px;\n  height: 38px;\n  float: right;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top-banner": "TopBanner_top-banner__82UmL",
	"text": "TopBanner_text__0J5As",
	"user-btn": "TopBanner_user-btn__EqunN"
};
export default ___CSS_LOADER_EXPORT___;
