import axios from "axios";
import { getJwtToken } from "../js/storage";
import { QueryUrlBuilder } from "../js/util";
import Path from "./path";
var Hashes = require("jshashes");

export const login = async (password) => {
  let params = new FormData();
  params.append("password", password);
  const response = await axios.post(Path.MANAGER_LOGIN, params);
  return response;
};

export const createAsset = async (name) => {
  let params = new FormData();
  params.append("name", name);
  const response = await axios.post(Path.ASSET, params, {
    headers: {
      Authorization: getJwtToken(),
    },
  });
  return response;
};

export const getOwnAssets = async () => {
  const response = await axios.get(Path.GET_ASSETS, {
    headers: { Authorization: getJwtToken() },
  });
  return response;
};

export const createGroup = async (
  assetId,
  groupName,
  anteSize,
  smallBlindSize,
  playerNum
) => {
  let params = new FormData();
  params.append("asset_id", assetId);
  params.append("group_name", groupName);
  params.append("ante_size", anteSize);
  params.append("small_blind_size", smallBlindSize);
  params.append("player_num", playerNum);
  const response = await axios.post(Path.GROUP, params, {
    headers: {
      Authorization: getJwtToken(),
    },
  });
  return response;
};

export const uploadRange = async (assetId, rangeFile, groupName) => {
  let params = new FormData();
  params.append("asset_id", assetId);
  params.append("group_name", groupName);
  params.append("range_file", rangeFile, rangeFile.name);
  const response = await axios.post(Path.UPLOAD_RANGE, params, {
    headers: {
      Authorization: getJwtToken(),
    },
  });
  return response;
};

export const getGroupsByAsset = async (assetId) => {
  let url = new QueryUrlBuilder(Path.GET_GROUPS_BY_ASSET)
    .set("asset_id", assetId)
    .build();
  const response = await axios.get(url, {
    headers: { Authorization: getJwtToken() },
  });
  return response;
};

export const deleteGroup = async (assetId, groupName) => {
  let params = new FormData();
  params.append("asset_id", assetId);
  params.append("group_name", groupName);
  const response = await axios.post(Path.DELETE_GROUP, params, {
    headers: {
      Authorization: getJwtToken(),
    },
  });
  return response;
};

export const clearGroup = async (assetId, groupName) => {
  let params = new FormData();
  params.append("asset_id", assetId);
  params.append("group_name", groupName);
  const response = await axios.post(Path.CLEAR_GROUP, params, {
    headers: {
      Authorization: getJwtToken(),
    },
  });
  return response;
};

export const getGroupRangeCount = async (assetId, groupName) => {
  let url = new QueryUrlBuilder(Path.GET_GROUP_RANGE_COUNT)
    .set("asset_id", assetId)
    .set("group_name", groupName)
    .build();
  const response = await axios.get(url, {
    headers: { Authorization: getJwtToken() },
  });
  return response;
};

export const searchUsers = async (username) => {
  let url = new QueryUrlBuilder(Path.SEARCH_USER)
    .set("username", username)
    .build();
  const response = await axios.get(url, {
    headers: { Authorization: getJwtToken() },
  });
  return response;
};

export const grantAssetToUser = async (assetId, userId) => {
  let params = new FormData();
  params.append("asset_id", assetId);
  params.append("user_id", userId);
  const response = await axios.post(Path.GRANT_ASSET, params, {
    headers: {
      Authorization: getJwtToken(),
    },
  });
  return response;
};

export const ungrantAssetToUser = async (assetId, userId) => {
  let params = new FormData();
  params.append("asset_id", assetId);
  params.append("user_id", userId);
  const response = await axios.post(Path.UNGRANT_ASSET, params, {
    headers: {
      Authorization: getJwtToken(),
    },
  });
  return response;
};

export const getOwnAssetUsers = async (assetId) => {
  let url = new QueryUrlBuilder(Path.GET_OWN_ASSET_USERS)
    .set("asset_id", assetId)
    .build();
  const response = await axios.get(url, {
    headers: { Authorization: getJwtToken() },
  });
  return response;
};

export const searchAssets = async (assetName) => {
  let url = new QueryUrlBuilder(Path.SEARCH_ASSET)
    .set("name", assetName)
    .build();
  const response = await axios.get(url, {
    headers: { Authorization: getJwtToken() },
  });
  return response;
};

export const createPlan = async (planName, assets, price, durationDay) => {
  let params = new FormData();
  params.append("name", planName);
  params.append("assets", JSON.stringify(assets));
  params.append("price", price);
  params.append("duration_day", durationDay);
  const response = await axios.post(Path.PLAN, params, {
    headers: {
      Authorization: getJwtToken(),
    },
  });
  return response;
};

export const getAllPlan = async () => {
  let url = new QueryUrlBuilder(Path.GET_ALL_PLAN).build();
  const response = await axios.get(url, {
    headers: { Authorization: getJwtToken() },
  });
  return response;
};

export const deletePlan = async (planName) => {
  let params = new FormData();
  params.append("name", planName);
  const response = await axios.post(Path.DELETE_PLAN, params, {
    headers: {
      Authorization: getJwtToken(),
    },
  });
  return response;
};

export const createPlanGroupByRegisterDays = async (
  planName,
  startDaysBefore,
  endDaysBefore
) => {
  let params = new FormData();
  params.append("name", planName);
  params.append("start_days_before", startDaysBefore);
  params.append("end_days_before", endDaysBefore);
  const response = await axios.post(
    Path.CREATE_PLAN_GROUP_BY_REGISTER_DAYS,
    params,
    {
      headers: {
        Authorization: getJwtToken(),
      },
    }
  );
  return response;
};

export const getAllPlanGroups = async () => {
  let url = new QueryUrlBuilder(Path.GET_ALL_PLAN_GROUPS).build();
  const response = await axios.get(url, {
    headers: { Authorization: getJwtToken() },
  });
  return response;
};

export const deletePlanGroup = async (planGroupId) => {
  let params = new FormData();
  params.append("id", planGroupId);
  const response = await axios.post(Path.DELETE_PLAN_GROUP, params, {
    headers: {
      Authorization: getJwtToken(),
    },
  });
  return response;
};

export const addPlanToPlanGroup = async (planGroupId, planId) => {
  let params = new FormData();
  params.append("plan_group_id", planGroupId);
  params.append("plan_id", planId);
  const response = await axios.post(Path.ADD_PLAN_TO_PLAN_GROUP, params, {
    headers: {
      Authorization: getJwtToken(),
    },
  });
  return response;
};

export const searchPlansByName = async (planName) => {
  let url = new QueryUrlBuilder(Path.SEARCH_PLANS_BY_NAME)
    .set("name", planName)
    .build();
  const response = await axios.get(url, {
    headers: { Authorization: getJwtToken() },
  });
  return response;
};

export const getPlansFromPlanGroup = async (planGroupId) => {
  let url = new QueryUrlBuilder(Path.GET_PLANS_FROM_PLAN_GROUP)
    .set("plan_group_id", planGroupId)
    .build();
  const response = await axios.get(url, {
    headers: { Authorization: getJwtToken() },
  });
  return response;
};

export const setPlanHidden = async (planId, hidden) => {
  let params = new FormData();
  params.append("plan_id", planId);
  params.append("hidden", hidden);
  const response = await axios.post(Path.SET_PLAN_HIDDEN, params, {
    headers: {
      Authorization: getJwtToken(),
    },
  });
  return response;
};

export const setPlanIntroduction = async (planId, introduction) => {
  let params = new FormData();
  params.append("plan_id", planId);
  params.append("introduction", introduction);
  const response = await axios.post(Path.SET_PLAN_INTRODUCTION, params, {
    headers: {
      Authorization: getJwtToken(),
    },
  });
  return response;
};

export const setPlanUseCase = async (planId, useCase) => {
  let params = new FormData();
  params.append("plan_id", planId);
  params.append("use_case", useCase);
  const response = await axios.post(Path.SET_PLAN_USECASE, params, {
    headers: {
      Authorization: getJwtToken(),
    },
  });
  return response;
};
