// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GroupManager_group-manager__GGCU1 {\n  width: 100%;\n  height: 100%;\n  position: relative;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group-manager": "GroupManager_group-manager__GGCU1"
};
export default ___CSS_LOADER_EXPORT___;
