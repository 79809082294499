import React, { Component, useEffect, useState } from "react";
import styles from "./GroupManagerModal.module.less";
import {
  Box,
  Button,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
} from "@mui/material";
import { getGroupsByAsset } from "../../../../../api/api";
import { FixedSizeList } from "react-window";
import CreateGroupModalOpener from "./create-group-modal/CreateGroupModal";
import GroupCardModalOpener from "./group-card-modal/GroupCardModal";

function GroupManagerModal(props) {
  const [groups, setGroups] = useState([]);

  const fetchGroups = async () => {
    try {
      const res = await getGroupsByAsset(props.assetId);
      setGroups(res.data);
    } catch (error) {
      console.log("获取失败");
    }
  };

  useEffect(() => {
    fetchGroups();
  }, [props.isOpened]);

  return (
    <div>
      <Modal
        open={props.isOpened}
        onClose={() => {
          props.setOpen(false);
        }}
      >
        <Box className={`flex-col ${styles["modal-box"]}`}>
          <p className={`${styles["content"]}`}>资产内的范围包</p>
          <FixedSizeList
            height={260}
            width={360}
            itemSize={46}
            itemCount={groups.length}
            overscanCount={5}
          >
            {({ index, style }) => {
              const item = groups[index];
              return (
                <GroupCardModalOpener
                  key={index}
                  style={style}
                  group={item}
                  assetId={props.assetId}
                ></GroupCardModalOpener>
              );
            }}
          </FixedSizeList>
          <CreateGroupModalOpener
            assetId={props.assetId}
          ></CreateGroupModalOpener>
          <Button
            variant="contained"
            onClick={() => {
              fetchGroups();
            }}
            style={{ width: "100%", margin: "10px" }}
          >
            刷新
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default function GroupManagerModalOpener(props) {
  const [isOpened, setOpen] = useState(false);
  return (
    <div>
      <Button
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        编辑资产
      </Button>
      <GroupManagerModal
        assetId={props.assetId}
        isOpened={isOpened}
        setOpen={setOpen}
      ></GroupManagerModal>
    </div>
  );
}
