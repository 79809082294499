import { Button } from "@mui/material";
import styles from "./PlanCard.module.less";
import { deletePlan, setPlanHidden } from "../../../../api/api";
import PlanAssetsModalOpener from "./plan-assets-modal/PlanAssetsModal";
import PlanStoreAttributesModalOpener from "./plan-store-attributes-modal/PlanStoreAttributesModal";

export default function PlanCard(props) {
  return (
    <div className={`${styles["plan-card"]}`}>
      <p className={`${styles["text"]}`}>{"方案名:" + props.plan.name}</p>
      <p className={`${styles["text"]}`}>{"价格:" + props.plan.price}</p>
      <p className={`${styles["text"]}`}>
        {"创建时间:" + props.plan.createdAt}
      </p>
      <p className={`${styles["text"]}`}>{"隐藏状态:" + props.plan.hidden}</p>
      <p className={`${styles["text"]}`}>
        {"订阅有效期:" + props.plan.durationDay + " 天"}
      </p>
      <Button
        variant="contained"
        onClick={async () => {
          await setPlanHidden(props.plan.id, true);
          await props.refetchPlans();
        }}
      >
        隐藏方案
      </Button>
      <Button
        style={{ marginTop: "10px" }}
        variant="contained"
        onClick={async () => {
          await setPlanHidden(props.plan.id, false);
          await props.refetchPlans();
        }}
      >
        取消隐藏
      </Button>
      <PlanAssetsModalOpener
        getAssetNameById={props.getAssetNameById}
        assetIds={props.assetIds}
      ></PlanAssetsModalOpener>
      <PlanStoreAttributesModalOpener
        plan={props.plan}
        refetchPlans={props.refetchPlans}
      ></PlanStoreAttributesModalOpener>
    </div>
  );
}
