import { useState } from "react";
import styles from "./PlanAssetsModal.module.less";
import { Box, Button, Modal } from "@mui/material";
import { FixedSizeList } from "react-window";

function PlanAssetsModal(props) {
  return (
    <div>
      <Modal
        open={props.isOpened}
        onClose={() => {
          props.setOpen(false);
        }}
      >
        <Box className={`flex-col ${styles["modal-box"]}`}>
          <p className={`${styles["content"]}`}>方案内的资产</p>
          <FixedSizeList
            height={260}
            width={360}
            itemSize={46}
            itemCount={props.assetIds.length}
            overscanCount={5}
          >
            {({ index, style }) => {
              const assetId = props.assetIds[index];
              return <p>{props.getAssetNameById(assetId)}</p>;
            }}
          </FixedSizeList>
        </Box>
      </Modal>
    </div>
  );
}

export default function PlanAssetsModalOpener(props) {
  const [isOpened, setOpen] = useState(false);
  return (
    <div style={{ marginTop: "10px" }}>
      <Button
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        查看方案内的资产
      </Button>
      <PlanAssetsModal
        isOpened={isOpened}
        setOpen={setOpen}
        assetIds={props.assetIds}
        getAssetNameById={props.getAssetNameById}
      ></PlanAssetsModal>
    </div>
  );
}
