// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RightContainer_right-container__4qX25 {\n  background-color: #cac9c9;\n  width: calc(100% - 220px);\n}\n.RightContainer_right-container__4qX25 .RightContainer_note-text__Npx6r {\n  font-size: x-large;\n  margin-left: 3%;\n  margin-bottom: 0px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"right-container": "RightContainer_right-container__4qX25",
	"note-text": "RightContainer_note-text__Npx6r"
};
export default ___CSS_LOADER_EXPORT___;
