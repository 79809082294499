// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateGroupModal_modal-box__ngwNn {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 400px;\n  height: 400px;\n  background-color: white;\n  border: 2px solid #000;\n}\n.CreateGroupModal_modal-box__ngwNn .CreateGroupModal_content__lys6d {\n  margin-right: 15px;\n  font-size: 18px;\n  line-height: 20px;\n  color: #224e77;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-box": "CreateGroupModal_modal-box__ngwNn",
	"content": "CreateGroupModal_content__lys6d"
};
export default ___CSS_LOADER_EXPORT___;
