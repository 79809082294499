import React, { Component } from "react";
import {
  createGroup,
  getOwnAssets,
  createAsset,
  getAllPlan,
  getAllPlanGroups,
} from "../../api/api";
import TopBanner from "../../components/top-banner/TopBanner";
import styles from "./GroupManager.module.less";
import LeftContainer from "./left-container/LeftContainer";
import RightContainer from "./right-container/RightContainer";
import { getJwtToken } from "../../js/storage";

export default class GroupManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      assets: [],
      plans: [],
      planGroups: [],
      userName: getJwtToken() == null ? "请登录" : "管理员",
    };
    const ownAssetsReq = getOwnAssets();
    ownAssetsReq.then((response) => {
      this.setState({ assets: response.data });
    });
    const plansReq = getAllPlan();
    plansReq.then((response) => {
      this.setState({ plans: response.data });
    });
    const planGroupsReq = getAllPlanGroups();
    planGroupsReq.then((response) => {
      this.setState({ planGroups: response.data });
    });
  }

  getAssetNameById = (assetId) => {
    let result = this.state.assets.filter((item) => item.id == assetId)[0].name;
    return result;
  };

  refetchPlans = async () => {
    const res = await getAllPlan();
    this.setState({ plans: res.data });
  };

  refetchPlanGroups = async () => {
    const res = await getAllPlanGroups();
    this.setState({ planGroups: res.data });
  };

  handleCreateGroup = async (
    groupName,
    anteSize,
    smallBlindSize,
    playerNum
  ) => {
    if (groupName == null) {
      alert("请输入包名");
      return false;
    }
    if (anteSize == null) {
      alert("请输入Ante尺寸");
      return false;
    }
    if (smallBlindSize == null) {
      alert("请输入小盲尺寸");
      return false;
    }
    if (playerNum == null) {
      alert("请输入牌桌人数");
      return false;
    }

    if (isNaN(anteSize)) {
      alert("请确保Ante尺寸是整数");
      return false;
    }
    if (isNaN(smallBlindSize)) {
      alert("请确保小盲尺寸是整数");
      return false;
    }
    if (isNaN(playerNum)) {
      alert("请确保牌桌人数是整数");
      return false;
    }

    const response = await createGroup(
      groupName,
      anteSize,
      smallBlindSize,
      playerNum
    );
    return true;
  };

  handleCreateAsset = async (name) => {
    if (name == null) {
      alert("请输入资产名称");
      return false;
    }
    const response = await createAsset(name);
    const assetsResponse = await getOwnAssets();
    this.setState({ assets: assetsResponse.data });
    return true;
  };

  render() {
    return (
      <div className={`${styles["group-manager"]}`}>
        <TopBanner userName={this.state.userName}></TopBanner>
        <div
          className="flex-row"
          style={{
            width: "100%",
            minHeight: "100%",
            maxHeight: "max-content",
            position: "absolute",
          }}
        >
          <LeftContainer
            handleCreateGroup={this.handleCreateGroup}
            handleCreateAsset={this.handleCreateAsset}
            groups={this.state.groups}
          ></LeftContainer>
          <RightContainer
            groups={this.state.groups}
            assets={this.state.assets}
            userName={this.state.userName}
            plans={this.state.plans}
            planGroups={this.state.planGroups}
            refetchPlans={this.refetchPlans}
            refetchPlanGroups={this.refetchPlanGroups}
            getAssetNameById={this.getAssetNameById}
          ></RightContainer>
        </div>
      </div>
    );
  }
}
