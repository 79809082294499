// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GroupCardModal_modal-box__NYe4D {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 400px;\n  height: 400px;\n  background-color: white;\n  border: 2px solid #000;\n}\n.GroupCardModal_modal-box__NYe4D .GroupCardModal_content__xEnsa {\n  margin-right: 15px;\n  font-size: 18px;\n  line-height: 20px;\n  color: #224e77;\n}\n.GroupCardModal_modal-box__NYe4D .GroupCardModal_text__1rnXs {\n  margin-top: 5px;\n  text-align: center;\n}\n.GroupCardModal_modal-box__NYe4D .GroupCardModal_btn__JBW3c {\n  margin-top: 5px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-box": "GroupCardModal_modal-box__NYe4D",
	"content": "GroupCardModal_content__xEnsa",
	"text": "GroupCardModal_text__1rnXs",
	"btn": "GroupCardModal_btn__JBW3c"
};
export default ___CSS_LOADER_EXPORT___;
