import { Button } from "@mui/material";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import styles from "./TopBanner.module.less";

export default class TopBanner extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={`${styles["top-banner"]}`}>
        <span className={`vertical-center ${styles["text"]}`}>Durrange</span>
        <Link to="/login">
          <Button
            variant="contained"
            className={`vertical-center ${styles["user-btn"]}`}
          >
            {this.props.userName}
          </Button>
        </Link>
      </div>
    );
  }
}
