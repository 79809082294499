import { Button } from "@mui/material";
import { deletePlanGroup } from "../../../../api/api";
import styles from "./PlanGroupCard.module.less";
import ManagePlanModalOpener from "./manage-plan-modal/ManagePlanModal";

export default function PlanGroupCard(props) {
  const planGroupId = props.planGroup.id;
  return (
    <div className={`${styles["plan-group-card"]}`}>
      <p className={`${styles["text"]}`}>{"id:" + planGroupId}</p>
      <p className={`${styles["text"]}`}>
        {"方案组名称:" + props.planGroup.name}
      </p>
      <p className={`${styles["text"]}`}>
        {"可见规则:注册" + props.planGroup.displayRule.endDaysBefore + "天后可见"}
      </p>
      <p className={`${styles["text"]}`}>
        {"不可见规则:注册" + props.planGroup.displayRule.startDaysBefore + "天后不可见"}
      </p>
      <Button
        variant="contained"
        onClick={async () => {
          await deletePlanGroup(planGroupId);
          await props.refetch();
        }}
      >
        删除方案组
      </Button>
      <ManagePlanModalOpener planGroupId={planGroupId}></ManagePlanModalOpener>
    </div>
  );
}
