// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlanGroupCard_plan-group-card__8tOPf {\n  width: 300px;\n  height: 300px;\n  margin-left: 3%;\n  margin-top: 3%;\n  background-color: #ffffff;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.PlanGroupCard_plan-group-card__8tOPf .PlanGroupCard_text__nMS6W {\n  margin-top: 5px;\n  text-align: center;\n}\n.PlanGroupCard_plan-group-card__8tOPf .PlanGroupCard_btn__WCiKK {\n  position: absolute;\n  left: 50%;\n  transform: translate(-50%, 0%);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"plan-group-card": "PlanGroupCard_plan-group-card__8tOPf",
	"text": "PlanGroupCard_text__nMS6W",
	"btn": "PlanGroupCard_btn__WCiKK"
};
export default ___CSS_LOADER_EXPORT___;
