import { Box, Button, Modal, TextField } from "@mui/material";
import React, { Component, useState } from "react";
import styles from "./CreateGroupModal.module.less";
import { createGroup } from "../../../../../../api/api";

function CreateGroupModal(props) {
  const [groupName, setGroupName] = useState(null);
  const [anteSize, setAnteSize] = useState(null);
  const [smallBlindSize, setSmallBlindSize] = useState(null);
  const [playerNum, setPlayerNum] = useState(null);

  const handleCreateGroup = async () => {
    try {
      const res = await createGroup(
        props.assetId,
        groupName,
        anteSize,
        smallBlindSize,
        playerNum
      );
      props.setOpen(false);
    } catch (error) {
      console.log("创建范围包失败");
    }
  };

  return (
    <div>
      <Modal
        open={props.isOpened}
        onClose={() => {
          props.setOpen(false);
        }}
      >
        <Box className={`flex-col ${styles["modal-box"]}`}>
          <div className="flex-col justify-around global-center">
            <p className={`${styles["content"]}`}>新建范围包</p>
            <TextField
              variant="outlined"
              label="包名"
              onChange={(event) => {
                setGroupName(event.target.value);
              }}
            ></TextField>
            <TextField
              variant="outlined"
              label="Ante尺寸"
              onChange={(event) => {
                setAnteSize(event.target.value);
              }}
            ></TextField>
            <TextField
              variant="outlined"
              label="小盲尺寸"
              onChange={(event) => {
                setSmallBlindSize(event.target.value);
              }}
            ></TextField>
            <TextField
              variant="outlined"
              label="牌桌人数"
              onChange={(event) => {
                setPlayerNum(event.target.value);
              }}
            ></TextField>
            <Button variant="contained" onClick={handleCreateGroup}>
              创建
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default function CreateGroupModalOpener(props) {
  const [isOpened, setOpen] = useState(false);
  return (
    <div style={{ width: "100%" }}>
      <Button
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
        style={{ width: "100%" }}
      >
        新增范围包
      </Button>
      <CreateGroupModal
        assetId={props.assetId}
        isOpened={isOpened}
        setOpen={setOpen}
      ></CreateGroupModal>
    </div>
  );
}
