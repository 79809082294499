import { Box, Button, Modal, TextField } from "@mui/material";
import styles from "./CreatePlanGroupModal.module.less";
import { useState } from "react";
import { createPlanGroupByRegisterDays } from "../../../../api/api";

function CreatePlanGroupModal(props) {
  const [name, setName] = useState(null);
  const [startDaysBefore, setStartDaysBefore] = useState(36500);
  const [endDaysBefore, setEndDaysBefore] = useState(0);
  return (
    <div>
      <Modal
        open={props.isOpened}
        onClose={() => {
          props.setOpen(false);
        }}
      >
        <Box className={`flex-col ${styles["modal-box"]}`}>
          <p className={`${styles["content"]}`}>创建方案组-注册时间规则</p>
          <TextField
            variant="outlined"
            label="方案组名称"
            onChange={(event) => {
              setName(event.target.value);
            }}
          ></TextField>
          <TextField
            variant="outlined"
            label="注册A天后不可见(可选,不填则在触发可见规则之后,将永远可见)"
            onChange={(event) => {
              setStartDaysBefore(event.target.value);
            }}
          ></TextField>
          <TextField
            variant="outlined"
            label="注册B天后可见(可选,不填则所有人都可见)"
            onChange={(event) => {
              setEndDaysBefore(event.target.value);
            }}
          ></TextField>
          <Button
            variant="contained"
            onClick={async () => {
              await createPlanGroupByRegisterDays(
                name,
                startDaysBefore,
                endDaysBefore
              );
              props.setOpen(false);
            }}
          >
            创建
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default function CreatePlanGroupModalOpener(props) {
  const [isOpened, setOpen] = useState(false);
  return (
    <div className={`${styles["tool-btn"]}`}>
      <Button
        className={`${styles["tool-btn"]}`}
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        创建方案组
      </Button>
      <CreatePlanGroupModal
        isOpened={isOpened}
        setOpen={setOpen}
      ></CreatePlanGroupModal>
    </div>
  );
}
