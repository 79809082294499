// const BACKEND_HOST = "http://localhost:8080";
const BACKEND_HOST = "https://durrange.xyz";

const Path = {
  MANAGER_LOGIN: BACKEND_HOST + "/manager/login",
  ASSET: BACKEND_HOST + "/asset",
  GROUP: BACKEND_HOST + "/group",
  CLEAR_GROUP: BACKEND_HOST + "/group/clear",
  DELETE_GROUP: BACKEND_HOST + "/group/delete",
  GET_GROUP_RANGE_COUNT: BACKEND_HOST + "/group/range-count",
  GET_ASSETS: BACKEND_HOST + "/assets",
  GET_GROUPS_BY_ASSET: BACKEND_HOST + "/groups",
  UPLOAD_RANGE: BACKEND_HOST + "/range",
  SEARCH_USER: BACKEND_HOST + "/user/search",
  GRANT_ASSET: BACKEND_HOST + "/asset/grant",
  UNGRANT_ASSET: BACKEND_HOST + "/asset/ungrant",
  GET_OWN_ASSET_USERS: BACKEND_HOST + "/asset/own/users",
  SEARCH_ASSET: BACKEND_HOST + "/asset/search",
  PLAN: BACKEND_HOST + "/plan",
  GET_ALL_PLAN: BACKEND_HOST + "/plans/all",
  DELETE_PLAN: BACKEND_HOST + "/plan/delete",
  CREATE_PLAN_GROUP_BY_REGISTER_DAYS:
    BACKEND_HOST + "/plan/group/register-days",
  GET_ALL_PLAN_GROUPS: BACKEND_HOST + "/plan/groups/all",
  DELETE_PLAN_GROUP: BACKEND_HOST + "/plan/group/delete",
  SEARCH_PLANS_BY_NAME: BACKEND_HOST + "/plans/search",
  GET_PLANS_FROM_PLAN_GROUP: BACKEND_HOST + "/plan/group/plans",
  ADD_PLAN_TO_PLAN_GROUP: BACKEND_HOST + "/plan/group/add-plan",
  SET_PLAN_HIDDEN: BACKEND_HOST + "/plan/hidden",
  SET_PLAN_INTRODUCTION: BACKEND_HOST + "/plan/introduction",
  SET_PLAN_USECASE: BACKEND_HOST + "/plan/usecase",
};

export default Path;
