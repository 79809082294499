import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import styles from "./CreatePlanModal.module.less";
import { createPlan, searchAssets } from "../../../../api/api";
import { Stack } from "react-bootstrap";

const SearchBar = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [assetMap, setAssetMap] = useState({});
  const [selectedAsset, setSelectedAsset] = useState(null);

  const handleSearch = async (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    const response = await searchAssets(term);
    setAssetMap(
      response.data.reduce((acc, obj) => {
        acc[obj.name] = obj;
        return acc;
      }, new Map())
    );
    const assetNames = response.data.map((item) => item.name);
    setOptions(assetNames);
  };

  const handleOptionSelect = (event, value) => {
    if (value == null) {
      return;
    }
    setSearchTerm(value);
    setSelectedAsset(assetMap[value]);
  };

  const handleAdd = async (event) => {
    props.setAssets([...props.assets, selectedAsset.name]);
  };

  return (
    <Autocomplete
      freeSolo
      options={options}
      inputValue={searchTerm}
      onInputChange={handleSearch}
      onChange={handleOptionSelect}
      style={{ width: "230px" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="搜索"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleAdd}>
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

function PlanModal(props) {
  const [assets, setAssets] = useState([]);
  const [name, setName] = useState(null);
  const [price, setPrice] = useState(0);
  const [durationDay, setDurationDay] = useState(30);

  return (
    <div>
      <Modal
        open={props.isOpened}
        onClose={() => {
          props.setOpen(false);
        }}
      >
        <Box className={`flex-col ${styles["modal-box"]}`}>
          <div className="flex-col justify-around global-center">
            <p className={`${styles["content"]}`}>创建方案</p>
            <TextField
              variant="outlined"
              label="方案名称"
              onChange={(event) => {
                setName(event.target.value);
              }}
            ></TextField>
            <TextField
              variant="outlined"
              label="价格"
              onChange={(event) => {
                setPrice(event.target.value);
              }}
            ></TextField>
            <TextField
              variant="outlined"
              label="订阅有效期(天)"
              onChange={(event) => {
                setDurationDay(event.target.value);
              }}
            ></TextField>
            <SearchBar assets={assets} setAssets={setAssets}></SearchBar>
            <Stack direction="row" spacing={1}>
              {assets.map((item, index) => {
                return (
                  <Chip
                    key={index}
                    label={item}
                    variant="outlined"
                    onDelete={() => {
                      setAssets(assets.filter((asset) => asset != item));
                    }}
                  ></Chip>
                );
              })}
            </Stack>
            <Button
              variant="contained"
              onClick={async () => {
                await createPlan(name, assets, price, durationDay);
                props.setOpen(false);
              }}
            >
              创建
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default function CreatePlanModalOpener(props) {
  const [isOpened, setOpen] = useState(false);
  return (
    <div className={`${styles["tool-btn"]}`}>
      <Button
        className={`${styles["tool-btn"]}`}
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        创建方案
      </Button>
      <PlanModal
        assetId={props.assetId}
        isOpened={isOpened}
        setOpen={setOpen}
      ></PlanModal>
    </div>
  );
}
