import { Box, Button, Modal, TextField } from "@mui/material";
import React, { Component } from "react";
import styles from "./CreateAssetModal.module.less";

export default class CreateAssetModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
      name: null,
    };
  }

  setOpen = (status) => {
    this.setState({ isOpened: status });
  };

  onChangeAssetName = (event) => {
    this.setState({ name: event.target.value });
  };

  handleClick = async () => {
    let result = await this.props.handleCreateAsset(
      this.state.name
    );
    if (result) {
      this.setOpen(false);
    }
  };

  render() {
    return (
      <div>
        <Modal
          open={this.state.isOpened}
          onClose={() => {
            this.setOpen(false);
          }}
        >
          <Box className={`flex-col ${styles["modal-box"]}`}>
            <div className="flex-col justify-around global-center">
              <p className={`${styles["content"]}`}>新建资产</p>
              <TextField
                variant="outlined"
                label="资产名"
                onChange={this.onChangeAssetName}
              ></TextField>
              <Button variant="contained" onClick={this.handleClick}>
                创建
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
    );
  }
}
