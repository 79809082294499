import { Button } from "@mui/material";
import React, { Component, useRef } from "react";
import styles from "./AssetCard.module.less";
import GroupManagerModal from "./group-manage-modal/GroupManagerModal";
import GroupManagerModalOpener from "./group-manage-modal/GroupManagerModal";
import HolderEditModalOpener from "./holder-edit-modal/HolderEditModal";

export default function AssetCard(props) {
  return (
    <div className={`${styles["asset-card"]}`}>
      <p className={`${styles["text"]}`}>{props.asset.name}</p>
      <GroupManagerModalOpener
        assetId={props.asset.id}
      ></GroupManagerModalOpener>
      <HolderEditModalOpener
        assetId={props.asset.id}
        assetName={props.asset.name}
      ></HolderEditModalOpener>
    </div>
  );
}
