// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LeftContainer_left-container__C2V5J {\n  background-color: #545050;\n  width: 220px;\n}\n.LeftContainer_left-container__C2V5J .LeftContainer_tool-btn__1Ei\\+T {\n  margin-left: 10%;\n  margin-top: 10%;\n  font-size: 20px;\n  line-height: 14px;\n  white-space: nowrap;\n  width: 136px;\n  height: 38px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"left-container": "LeftContainer_left-container__C2V5J",
	"tool-btn": "LeftContainer_tool-btn__1Ei+T"
};
export default ___CSS_LOADER_EXPORT___;
