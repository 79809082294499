import { Button } from "@mui/material";
import React, { Component } from "react";
import CreateAssetModal from "./create-asset-modal/CreateAssetModal";
import styles from "./LeftContainer.module.less";
import CreatePlanModalOpener from "./create-plan-modal/CreatePlanModal";
import CreatePlanGroupModalOpener from "./create-plangroup-modal/CreatePlanGroupModal";

export default class LeftContainer extends Component {
  createGroupModalRef = React.createRef();
  createAssetModalRef = React.createRef();
  uploadRangeModalRef = React.createRef();

  constructor(props) {
    super(props);
  }

  handleClickCreateGroup = () => {
    this.createGroupModalRef.current.setOpen(true);
  };
  handleClickUploadRange = () => {
    this.uploadRangeModalRef.current.setOpen(true);
  };
  handleClickCreateAsset = () => {
    this.createAssetModalRef.current.setOpen(true);
  };

  render() {
    return (
      <div className={`${styles["left-container"]}`}>
        <Button
          variant="contained"
          className={`${styles["tool-btn"]}`}
          onClick={this.handleClickCreateAsset}
        >
          新建资产
        </Button>
        <CreateAssetModal
          ref={this.createAssetModalRef}
          handleCreateAsset={this.props.handleCreateAsset}
        ></CreateAssetModal>
        <CreatePlanModalOpener></CreatePlanModalOpener>
        <CreatePlanGroupModalOpener></CreatePlanGroupModalOpener>
      </div>
    );
  }
}
