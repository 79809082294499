export class QueryUrlBuilder {
  constructor(path) {
    this.url = path + "?";
  }

  set(key, value) {
    let paramSeg = key + "=" + value + "&";
    this.url += paramSeg;
    return this;
  }

  build() {
    this.url = this.url.substring(0, this.url.length - 1);
    return this.url;
  }
}
