import {
  Box,
  Button,
  LinearProgress,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { Component, useState } from "react";
import styles from "./UploadRangeModal.module.less";
import { uploadRange } from "../../../../../../../api/api";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {props.uploadedCount + "/" + props.totalCount}
        </Typography>
      </Box>
    </Box>
  );
}

function UploadRangeModal(props) {
  const [progress, setProgress] = useState(0);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const onLocalGroupPathChange = async (event) => {
    let files = event.target.files;
    setTotalCount(files.length);

    let uploadedCount = 0;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      console.log("正在上传:" + file.name);
      const response = await uploadRange(props.assetId, file, props.group.name);
      console.log(response.data);
      uploadedCount += 1;
      setProgress(parseInt((uploadedCount / files.length) * 100));
      setUploadedCount(uploadedCount);
    }
  };

  return (
    <Modal
      open={props.isOpened}
      onClose={() => {
        props.setOpen(false);
      }}
    >
      <Box className={`flex-col ${styles["modal-box"]}`}>
        <div className="flex-col justify-around global-center">
          <p className={`${styles["text"]}`}>
            {"目标范围包:" + props.group.name}
          </p>
          <Button
            variant="contained"
            component="label"
            className={`${styles["modal-component"]}`}
          >
            选择范围包所在的文件夹
            <input
              type="file"
              webkitdirectory="true"
              onChange={onLocalGroupPathChange}
              hidden={true}
            />
          </Button>
          <Box sx={{ width: "200px" }}>
            <LinearProgressWithLabel
              variant="determinate"
              value={progress}
              uploadedCount={uploadedCount}
              totalCount={totalCount}
              className={`${styles["modal-component"]}`}
            ></LinearProgressWithLabel>
          </Box>
        </div>
      </Box>
    </Modal>
  );
}

export default function UploadRangeModalOpener(props) {
  const [isOpened, setOpen] = useState(false);
  return (
    <div style={{ width: "100%" }}>
      <Button
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
        style={{ width: "100%" }}
      >
        上传范围
      </Button>
      <UploadRangeModal
        assetId={props.assetId}
        group={props.group}
        isOpened={isOpened}
        setOpen={setOpen}
      ></UploadRangeModal>
    </div>
  );
}
