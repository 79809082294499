import styles from "./IcpBanner.module.less";

export default function IcpBanner(props) {
  return (
    <div className={`${styles["icp-banner"]}`}>
      <a href="https://beian.miit.gov.cn/" style={{ color: "white" }}>备案号:</a>
      <a href="https://beian.miit.gov.cn/" style={{ color: "white" }}>
        闽ICP备2022007601号-1
      </a>
    </div>
  );
}
