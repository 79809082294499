import React, { Component } from "react";
import AssetCard from "./asset-card/AssetCard";
import styles from "./RightContainer.module.less";
import PlanCard from "./plan-card/PlanCard";
import IcpBanner from "../icp-banner/IcpBanner";
import PlanGroupCard from "./plan-group-card/PlanGroupCard";

export default class RightContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={`${styles["right-container"]}`}>
        <p className={`${styles["note-text"]}`}>资产</p>
        <div className="flex-wrap">
          {this.props.assets.map((item, index) => {
            return (
              <AssetCard
                key={index}
                asset={item}
                userName={this.props.userName}
                groups={this.props.groups}
              ></AssetCard>
            );
          })}
        </div>
        <p className={`${styles["note-text"]}`}>方案</p>
        <div className="flex-wrap">
          {this.props.plans.map((item, index) => {
            return (
              <PlanCard
                key={index}
                plan={item}
                refetchPlans={this.props.refetchPlans}
                getAssetNameById={this.props.getAssetNameById}
                assetIds={item.assets}
              ></PlanCard>
            );
          })}
        </div>
        <p className={`${styles["note-text"]}`}>方案组</p>
        <div className="flex-wrap">
          {this.props.planGroups.map((item, index) => {
            return (
              <PlanGroupCard
                key={index}
                planGroup={item}
                refetch={this.props.refetchPlanGroups}
              ></PlanGroupCard>
            );
          })}
        </div>
        <IcpBanner></IcpBanner>
      </div>
    );
  }
}
