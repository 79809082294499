// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreatePlanModal_modal-box__CO7m0 {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 400px;\n  height: 400px;\n  background-color: white;\n  border: 2px solid #000;\n}\n.CreatePlanModal_modal-box__CO7m0 .CreatePlanModal_content__N\\+zHk {\n  margin-right: 15px;\n  font-size: 18px;\n  line-height: 20px;\n  color: #224e77;\n}\n.CreatePlanModal_tool-btn__OkqxD {\n  margin-left: 10%;\n  margin-top: 10%;\n  font-size: 20px;\n  line-height: 14px;\n  white-space: nowrap;\n  width: 136px;\n  height: 38px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-box": "CreatePlanModal_modal-box__CO7m0",
	"content": "CreatePlanModal_content__N+zHk",
	"tool-btn": "CreatePlanModal_tool-btn__OkqxD"
};
export default ___CSS_LOADER_EXPORT___;
