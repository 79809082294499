import { useState } from "react";
import {
  addPlanToPlanGroup,
  getPlansFromPlanGroup,
  searchPlansByName,
} from "../../../../../api/api";
import styles from "./ManagePlanModal.module.less";
import {
  Autocomplete,
  Box,
  Chip,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const SearchBar = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [planMap, setPlanMap] = useState({});
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handleSearch = async (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    const response = await searchPlansByName(term);
    setPlanMap(
      response.data.reduce((acc, obj) => {
        acc[obj.name] = obj;
        return acc;
      }, new Map())
    );
    const planNames = response.data.map((item) => item.name);
    setOptions(planNames);
  };

  const handleOptionSelect = (event, value) => {
    if (value == null) {
      return;
    }
    setSearchTerm(value);
    setSelectedPlan(planMap[value]);
  };

  const handleAdd = async (event) => {
    await addPlanToPlanGroup(props.planGroupId, selectedPlan.id);
    await props.refetch();
  };

  return (
    <Autocomplete
      freeSolo
      options={options}
      inputValue={searchTerm}
      onInputChange={handleSearch}
      onChange={handleOptionSelect}
      style={{ width: "230px" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="搜索"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleAdd}>
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

function ManagePlanModal(props) {
  const [plans, setPlans] = useState([]);

  const fetchPlans = async () => {
    const res = await getPlansFromPlanGroup(props.planGroupId);
    const result = res.data;
    setPlans(result);
  };

  return (
    <div>
      <Modal
        open={props.isOpened}
        onClose={() => {
          props.setOpen(false);
        }}
      >
        <Box className={`flex-col ${styles["modal-box"]}`}>
          <SearchBar
            refetch={fetchPlans}
            planGroupId={props.planGroupId}
          ></SearchBar>
          <Stack direction="row" spacing={1}>
            {plans.map((item, index) => {
              return (
                <Chip key={index} label={item.name} variant="outlined"></Chip>
              );
            })}
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

export default function ManagePlanModalOpener(props) {
  const [isOpened, setOpen] = useState(false);
  return (
    <div style={{ marginTop: "10px" }}>
      <Button
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        管理方案组
      </Button>
      <ManagePlanModal
        isOpened={isOpened}
        setOpen={setOpen}
        planGroupId={props.planGroupId}
      ></ManagePlanModal>
    </div>
  );
}
