// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IcpBanner_icp-banner__5dlnD {\n  background-color: #545050;\n  display: block;\n  position: fixed;\n  padding-left: 35%;\n  width: 100%;\n  bottom: 0px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icp-banner": "IcpBanner_icp-banner__5dlnD"
};
export default ___CSS_LOADER_EXPORT___;
