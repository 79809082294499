import { useEffect, useState } from "react";
import styles from "./PlanStoreAttributesModal.module.less";
import { Box, Button, Modal, TextField } from "@mui/material";
import { setPlanIntroduction, setPlanUseCase } from "../../../../../api/api";

function PlanStoreAttributesModal(props) {
  const [introduction, setIntroduction] = useState(props.plan.introduction);
  const [useCase, setUseCase] = useState(props.plan.useCase);

  const handleUpdate=async(event)=>{
    await setPlanIntroduction(props.plan.id, introduction);
    await setPlanUseCase(props.plan.id, useCase);
    await props.refetchPlans();
    props.setOpen(false);
  }

  return (
    <div>
      <Modal
        open={props.isOpened}
        onClose={() => {
          props.setOpen(false);
        }}
      >
        <Box className={`flex-col ${styles["modal-box"]}`}>
          <TextField
            variant="outlined"
            label="方案介绍(商城卡片)"
            value={introduction}
            onChange={(event) => {
              setIntroduction(event.target.value);
            }}
          ></TextField>
          <TextField
            style={{ marginTop: "10px" }}
            variant="outlined"
            label="方案场景(商城卡片)"
            value={useCase}
            onChange={(event) => {
              setUseCase(event.target.value);
            }}
          ></TextField>
          <Button variant="contained" onClick={handleUpdate}>
            确认更改
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default function PlanStoreAttributesModalOpener(props) {
  const [isOpened, setOpen] = useState(false);
  return (
    <div style={{ marginTop: "10px" }}>
      <Button
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        编辑方案的商城展示属性
      </Button>
      <PlanStoreAttributesModal
        isOpened={isOpened}
        setOpen={setOpen}
        plan={props.plan}
        refetchPlans={props.refetchPlans}
      ></PlanStoreAttributesModal>
    </div>
  );
}
