import React, { Component, useEffect, useState } from "react";
import styles from "./HolderEditModal.module.less";
import {
  Box,
  Button,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  TextField,
  IconButton,
  InputAdornment,
  Autocomplete,
  Chip,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  getGroupsByAsset,
  getOwnAssetUsers,
  grantAssetToUser,
  searchUsers,
  ungrantAssetToUser,
} from "../../../../../api/api";
import { FixedSizeList } from "react-window";

const SearchBar = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [userMap, setUserMap] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);

  const handleSearch = async (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    const response = await searchUsers(term);
    setUserMap(
      response.data.reduce((acc, obj) => {
        acc[obj.username] = obj;
        return acc;
      }, new Map())
    );
    const usernames = response.data.map((item) => item.username);
    setOptions(usernames);
  };

  const handleOptionSelect = (event, value) => {
    if (value == null) {
      return;
    }
    setSearchTerm(value);
    setSelectedUser(userMap[value]);
  };

  const handleAdd = async (event) => {
    await grantAssetToUser(props.assetId, selectedUser.id);
    props.fetchOwners();
  };

  return (
    <Autocomplete
      freeSolo
      options={options}
      inputValue={searchTerm}
      onInputChange={handleSearch}
      onChange={handleOptionSelect}
      style={{ width: "230px" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="搜索"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleAdd}>
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

function HolderEditModal(props) {
  const [owners, setOwners] = useState([]);

  const fetchOwners = async () => {
    const res = await getOwnAssetUsers(props.assetId);
    setOwners(res.data);
  };

  useEffect(() => {
    fetchOwners();
  }, [props.isOpened]);

  const handleDelete = async (user) => {
    await ungrantAssetToUser(props.assetId, user.id);
    fetchOwners();
  };

  return (
    <Modal
      open={props.isOpened}
      onClose={() => {
        props.setOpen(false);
      }}
    >
      <Box className={`flex-col ${styles["modal-box"]}`}>
        <p className={`${styles["content"]}`}>资产权限编辑:{props.assetName}</p>
        <SearchBar
          assetId={props.assetId}
          fetchOwners={fetchOwners}
        ></SearchBar>
        <Stack direction="row" spacing={1}>
          {owners.map((item, index) => {
            return (
              <Chip
                key={index}
                label={item.username ? item.username : item.id}
                variant="outlined"
                onDelete={() => {
                  handleDelete(item);
                }}
              ></Chip>
            );
          })}
        </Stack>
      </Box>
    </Modal>
  );
}

export default function HolderEditModalOpener(props) {
  const [isOpened, setOpen] = useState(false);
  return (
    <div style={{ marginTop: "10px" }}>
      <Button
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        编辑权限
      </Button>
      <HolderEditModal
        isOpened={isOpened}
        setOpen={setOpen}
        assetId={props.assetId}
        assetName={props.assetName}
      ></HolderEditModal>
    </div>
  );
}
