import "./App.css";

import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./views/login/LoginPage";
import GroupManager from "./views/group-manager/GroupManager";

export default class App extends Component {
  componentDidMount() {
    //blocking the default menu event of browser
    /* document.oncontextmenu = function (e) {
      e = e || window.event;
      return false;
    }; */
    //blocking the select text event of browser
    document.onselectstart = function (e) {
      e = e || window.event;
      return false;
    }; 
  }

  render() {
    return (
      <Routes>
        <Route path="/" element={<GroupManager />} />
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    );
  }
}
