// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AssetCard_asset-card__mQDkx {\n  width: 200px;\n  height: 200px;\n  margin-left: 3%;\n  margin-top: 3%;\n  background-color: #ffffff;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.AssetCard_asset-card__mQDkx .AssetCard_text__qyixZ {\n  margin-top: 5px;\n  text-align: center;\n}\n.AssetCard_asset-card__mQDkx .AssetCard_btn__o2IAZ {\n  position: absolute;\n  left: 50%;\n  transform: translate(-50%, 0%);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"asset-card": "AssetCard_asset-card__mQDkx",
	"text": "AssetCard_text__qyixZ",
	"btn": "AssetCard_btn__o2IAZ"
};
export default ___CSS_LOADER_EXPORT___;
