import {
  Box,
  Button,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  TextField,
} from "@mui/material";
import React, { Component, useEffect, useState } from "react";
import styles from "./GroupCardModal.module.less";
import UploadRangeModalOpener from "./upload-range-modal/UploadRangeModal";
import {
  clearGroup,
  deleteGroup,
  getGroupRangeCount,
} from "../../../../../../api/api";

function GroupCardModal(props) {
  const [rangeCount, setRangeCount] = useState(0);

  useEffect(() => {
    const updateRangeCount = async () => {
      const res = await getGroupRangeCount(props.assetId, props.group.name);
      setRangeCount(res.data.count);
    };
    updateRangeCount();
  });

  const handleClearGroup = async () => {
    const res = await clearGroup(props.assetId, props.group.name);
    props.setOpen(false);
    props.setOpen(true);
  };

  const handleDeleteGroup = async () => {
    const res = await deleteGroup(props.assetId, props.group.name);
    props.setOpen(false);
  };

  return (
    <Modal
      open={props.isOpened}
      onClose={() => {
        props.setOpen(false);
      }}
    >
      <Box className={`flex-col ${styles["modal-box"]}`}>
        <p className={`${styles["text"]}`}>{"ID:" + props.group.id}</p>
        <p className={`${styles["text"]}`}>{"包名:" + props.group.name}</p>
        <p className={`${styles["text"]}`}>
          {"ante尺寸:" + props.group.ante_size}
        </p>
        <p className={`${styles["text"]}`}>
          {"小盲尺寸:" + props.group.sb_size}
        </p>
        <p className={`${styles["text"]}`}>
          {"创建时间:" + props.group.createdAt}
        </p>
        <p className={`${styles["text"]}`}>
          {"适用牌桌人数:" + props.group.player_num + " 人"}
        </p>
        <p className={`${styles["text"]}`}>
          {"范围总计:" + rangeCount + " 件"}
        </p>
        <UploadRangeModalOpener
          group={props.group}
          assetId={props.assetId}
        ></UploadRangeModalOpener>
        <Button
          variant="contained"
          className={`${styles["btn"]}`}
          onClick={handleClearGroup}
        >
          重置整包
        </Button>
        <Button
          variant="contained"
          className={`${styles["btn"]}`}
          onClick={handleDeleteGroup}
        >
          删除整包
        </Button>
      </Box>
    </Modal>
  );
}

export default function GroupCardModalOpener(props) {
  const [isOpened, setOpen] = useState(false);
  return (
    <ListItem key={props.index} style={props.style}>
      <ListItemButton
        onClick={() => {
          console.log("Click");
          setOpen(true);
        }}
        component="a"
        href="#simple-list"
      >
        <ListItemText primary={props.group.name} />
      </ListItemButton>
      <GroupCardModal
        group={props.group}
        assetId={props.assetId}
        isOpened={isOpened}
        setOpen={setOpen}
      ></GroupCardModal>
    </ListItem>
  );
}
