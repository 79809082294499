// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoginPage_page__gkj29 {\n  background-color: #384e68;\n  width: 100%;\n  height: 100%;\n}\n.LoginPage_page__gkj29 .LoginPage_login-box__d7wzY {\n  height: 300px;\n  width: 300px;\n  background-color: white;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n.LoginPage_page__gkj29 .LoginPage_login-box__d7wzY .LoginPage_title__sWgGX {\n  margin-top: 24px;\n  font-size: 20px;\n  color: #224e77;\n  text-align: center;\n}\n.LoginPage_page__gkj29 .LoginPage_login-box__d7wzY .LoginPage_content__2qy4C {\n  margin-right: 15px;\n  font-size: 18px;\n  line-height: 20px;\n  color: #224e77;\n}\n.LoginPage_page__gkj29 .LoginPage_login-box__d7wzY .LoginPage_input-box__LUlWv {\n  margin-top: 10px;\n  margin-right: 30px;\n}\n.LoginPage_page__gkj29 .LoginPage_login-box__d7wzY .LoginPage_input-box__LUlWv .LoginPage_input-field__RkaGF {\n  width: 163px;\n  height: 25px;\n}\n.LoginPage_page__gkj29 .LoginPage_login-box__d7wzY .LoginPage_login-btn__7FBo2 {\n  width: 111px;\n  height: 32px;\n  margin: 34px auto;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "LoginPage_page__gkj29",
	"login-box": "LoginPage_login-box__d7wzY",
	"title": "LoginPage_title__sWgGX",
	"content": "LoginPage_content__2qy4C",
	"input-box": "LoginPage_input-box__LUlWv",
	"input-field": "LoginPage_input-field__RkaGF",
	"login-btn": "LoginPage_login-btn__7FBo2"
};
export default ___CSS_LOADER_EXPORT___;
