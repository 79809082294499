// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreatePlanGroupModal_modal-box__vLYLN {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 400px;\n  height: 400px;\n  background-color: white;\n  border: 2px solid #000;\n}\n.CreatePlanGroupModal_modal-box__vLYLN .CreatePlanGroupModal_content__zlw4D {\n  margin-right: 15px;\n  font-size: 18px;\n  line-height: 20px;\n  color: #224e77;\n}\n.CreatePlanGroupModal_tool-btn__dfWXh {\n  margin-left: 10%;\n  margin-top: 10%;\n  font-size: 20px;\n  line-height: 14px;\n  white-space: nowrap;\n  width: 136px;\n  height: 38px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-box": "CreatePlanGroupModal_modal-box__vLYLN",
	"content": "CreatePlanGroupModal_content__zlw4D",
	"tool-btn": "CreatePlanGroupModal_tool-btn__dfWXh"
};
export default ___CSS_LOADER_EXPORT___;
