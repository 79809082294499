import { Button, TextField } from "@mui/material";
import React, { Component } from "react";
import styles from "./LoginPage.module.less";
import { login } from "../../api/api";
import { getJwtToken, setJwtToken } from "../../js/storage";
import { Navigate } from "react-router-dom";

export default class LoginPage extends Component {
  state = {
    password: "",
    isLogin: false,
  };

  handleLogin = async () => {
    try {
      const response = await login(this.state.password);
      let token = response.data.jwt;
      setJwtToken(token);
      this.setState({ isLogin: true });
    } catch (error) {
      alert("登录失败:" + error);
    }
  };

  handlePasswordChange = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  render() {
    if (this.state.isLogin) {
      return <Navigate to="/"></Navigate>
    }
    return (
      <div className={`flex-col ${styles["page"]}`}>
        <div className={`flex-col ${styles["login-box"]}`}>
          <p className={`${styles["title"]}`}>登录</p>
          <div className={`justify-end ${styles["input-box"]}`}>
            <p className={`${styles["content"]}`}>管理员口令</p>
            <TextField
              onChange={this.handlePasswordChange}
              id="outlined-password-input"
              className={`${styles["input-field"]}`}
              label="管理员口令"
              type="password"
              autoComplete="current-password"
            />
          </div>
          <Button
            onClick={this.handleLogin}
            className={`${styles["login-btn"]}`}
            variant="contained"
          >
            登录
          </Button>
        </div>
      </div>
    );
  }
}
